import { autoinject, observable } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import {
    EdrAgentResult,
    EdrApiClient,
    EdrHost,
    EdrHostContainmentState,
    EdrSubscriptionResult
} from '../../services/cyber-api';
import { StateApi } from '../../services/state-api';
import { UxBlade } from '../../components/layout/ux-blade/ux-blade';
import { Router } from 'aurelia-router';
import moment from 'moment';
import { IFilter } from './components/fleet-health-filters/models/filter';
import { FleetHealthFilters } from './components/fleet-health-filters/fleet-health-filters';
import { FilterNames } from './components/fleet-health-filters/models/filter-names';
import { defaultFilters } from './components/fleet-health-filters/models/default-filters';

@autoinject
export class FleetHealthMonitor {
    private agentResults: EdrAgentResult[];
    private loading = false;
    private sortSettings = {
        column: 'LastPollTimestamp',
        direction: 'desc'
    };
    private skip: number = 0;
    private take: number = 25;
    private totalDataCount: number = undefined;
    private blade: UxBlade;
    private selectedAgent: EdrHost;
    private selectedAgentResult: EdrAgentResult;
    @observable private vesselSearch: string = undefined;
    private selectedSubscription: EdrSubscriptionResult | undefined = undefined;
    private subscriptions: EdrSubscriptionResult[] = [];
    private totalSubscriptionCount: number = undefined;
    private skipSubscription: number = 0;
    private takeSubscription: number = 25;
    private subscriptionLoading = false;
    private EdrHostContainmentStates: typeof EdrHostContainmentState = EdrHostContainmentState;

    // Filters: selected items
    private FilterNames: typeof FilterNames = FilterNames;

    private params: any;
    private filtersComponent: FleetHealthFilters;
    private filters: IFilter;
    private filteringEnabled: boolean = false;


    constructor(
        private http: HttpClient,
        private edrApi: EdrApiClient,
        private state: StateApi,
        private router: Router
    ) {
    }

    private async activate(params: any): Promise<any> {
        this.params = params;

        await this.fetchSubscriptions(0, true, false);
        this.selectedSubscription = params?.siteId ? this.subscriptions.find(s => s.siteId === params.siteId) : undefined;
    }

    private async attached(): Promise<void> {
        this.setFiltersFromRouteParams();
        if (!this.filtersComponent.hasActiveFilters)
            await this.resetData();
    }

    private setFiltersFromRouteParams(): void {
        // Use params to prepopulate the filters, which kicks in before 'attached'. Such as:
        if (this.params['up-to-date']) {
            let filterValue = JSON.parse(this.params['up-to-date']);
            if (filterValue === true) {
                this.filters.updated = 'up-to-date';
            } else if (filterValue === 'false') {
                this.filters.updated = 'out-of-date';
            }
        }

        if (this.params['edr-active']) {
            let filterValue = JSON.parse(this.params['edr-active']);
            if (filterValue === true) {
                this.filters.isEdrActive = 'edr-active';
            } else if (filterValue === 'false') {
                this.filters.isEdrActive = 'edr-inactive';
            }
        }

        if (this.params['is-contained']) {
            this.filters.isContained = JSON.parse(this.params['is-contained']);
        }

        if (this.params.search)
            this.filters.search = this.params.search;

        // Finally, set filtering to be enabled, allowing filter events to execute
        this.filteringEnabled = true;
    }

    private async clearFilters(): Promise<void> {
        await this.filtersComponent.clearFilters();

    }

    private async clearFilter(filterName: FilterNames): Promise<void> {
        switch (filterName) {
            case FilterNames.Updated:
                this.filters.updated = defaultFilters.updated;
                break;
            case FilterNames.IsContained:
                this.filters.isContained = defaultFilters.isContained;
                break;
            case FilterNames.Search:
                this.filters.search = defaultFilters.search;
                break;
        }
    }

    private async filter(filters: IFilter): Promise<void> {
        if (!this.filteringEnabled)
            return;

        this.filters = filters;

        await this.resetData();
    }

    private setUrlFilterParams(): void {
        const route = 'fleet-health-monitor';

        const params = new URLSearchParams();

        if (this.selectedSubscription)
            params.append('siteId', this.selectedSubscription.siteId.toString());

        const routeParams = {};
        for (const param of params)
            routeParams[param[0]] = param[1];

        this.router.navigateToRoute(route, routeParams);
    }

    private async setSubscription(subscription: EdrSubscriptionResult | undefined): Promise<void> {
        this.selectedSubscription = subscription;
        this.setUrlFilterParams();
        await this.resetData();
    }

    private async hostnameSearchChanged(): Promise<void> {
        this.setUrlFilterParams();
        await this.resetData();
    }

    private async vesselSearchChanged(): Promise<void> {
        this.selectedSubscription = undefined;
        await this.resetSubscriptionData();
        this.selectedSubscription = this.subscriptions[0];
        // trigger reload of table
        await this.resetData();
    }

    private async resetSubscriptionData(): Promise<void> {
        this.subscriptions = [];
        this.skipSubscription = 0;
        this.totalSubscriptionCount = undefined;
        if (this.blade)
            await this.blade.hide();
        await this.fetchSubscriptions(0, true, false);
    }

    private async resetData(): Promise<void> {
        this.agentResults = [];
        this.skip = 0;
        this.totalDataCount = undefined;
        if (this.blade)
            await this.blade.hide();
        await this.fetchData(0, true, false);
    }

    private async fetchSubscriptions(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left
        // to fetch
        if (this.totalSubscriptionCount === this.subscriptions.length) return;
        this.subscriptionLoading = true;
        const pagedResult = await this.edrApi.subscriptions(
            this.state.company(),
            this.takeSubscription,
            this.skipSubscription,
            this.vesselSearch
        );
        if (!pagedResult) {
            this.subscriptionLoading = false;
            return;
        }

        this.subscriptions = this.subscriptions.concat(pagedResult.items);

        this.totalSubscriptionCount = pagedResult.total;

        this.skipSubscription += this.takeSubscription;

        this.subscriptionLoading = false;
    }

    private async fetchData(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left
        // to fetch
        if (this.totalDataCount === this.agentResults.length) return;
        this.loading = true;
        const pagedResult = await this.edrApi.hosts(
            this.state.company(),
            this.selectedSubscription?.siteId,
            this.take,
            this.skip,
            `${this.sortSettings.column}:${this.sortSettings.direction}`,
            this.filters.search,
            this.filters.updated != 'all' ? (this.filters.updated === 'up-to-date') : undefined,
            this.filters.isContained || undefined,
            this.filters.isEdrActive != 'all' ? (this.filters.isEdrActive === 'edr-active') : undefined);
        if (!pagedResult) {
            this.loading = false;
            return;
        }

        this.agentResults = this.agentResults.concat(pagedResult.items);
        this.totalDataCount = pagedResult.total;
        this.skip += this.take;
        this.loading = false;
    }

    private async rowClick(item: EdrAgentResult): Promise<void> {
        let host = await this.edrApi.host(item.agentId, this.state.company());
        if (!host || item.status.toLowerCase() === 'uninstalled') return;
        this.selectedAgent = host;
        this.selectedAgentResult = item;
        await this.blade.show();
    }

    private async setSort(column: string): Promise<void> {
        // If sorting is set for a different column than what sorting is currently active on, set default to descending
        if (this.sortSettings.column !== column)
            this.sortSettings = {
                column,
                direction: 'desc'
            };
        else if (this.sortSettings.column === column)
            // Invert the sorting direction if the current column is already selected
            switch (this.sortSettings.direction) {
                case 'asc':
                    this.sortSettings.direction = 'desc';
                    break;
                case 'desc':
                    this.sortSettings.direction = 'asc';
                    break;
            }

        await this.resetData();
    }

    private lessThanThreeDaysAgo = (date) => {
        if (!date) return;
        return moment(date).isAfter(moment().subtract(3, 'days'));
    }
}
